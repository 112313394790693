const header = () => {
    return ( 
        <header>
            <div class='profile'></div>
            <h1>simonGuitar</h1>
            <p>explore below</p>
        </header>
     );
}
 
export default header;