import './app.scss';
import links from './assets/main-links';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as Youtube } from './images/youtube.svg';
import { ReactComponent as Soundcloud } from './images/soundcloud.svg';
import { ReactComponent as Instagram } from './images/instagram.svg';
import Header from './components/header';
import * as LINKS from './constants/links';

function App() {
  return (
    <div className="App">
      <section>
        <Container>
          <Header />
          <div className='socials'>
            <a target='_blank' href={LINKS.youtube}><Youtube /></a>
            <a target='_blank' href={LINKS.instagram}><Instagram /></a>
            <a target='_blank' href={LINKS.soundcloud}><Soundcloud /></a>
          </div>
          <div className='links'>
            {links.links.map((link, i) => {
              return (
                <a className='lilita-one-regular' target='_blank' key={i} href={link.link}>{link.name}</a>
              )
            })}
          </div>
        </Container>
      </section>
    </div>
  );
}

export default App;
