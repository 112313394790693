import * as LINKS from '../constants/links';

const links = {
    "links": [
        {
            "name": "youtube",
            "link": LINKS.youtube
        },
        {
            "name": "main website",
            "link": LINKS.website
        },
        {
            "name": "buy music",
            "link": LINKS.bandcamp
        },
        {
            "name": "soundcloud",
            "link": LINKS.soundcloud
        }
    ]
}

export default links